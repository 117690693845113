.card {

    // FUNCTIONALITY
    perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 52rem;
    
    &__side {
        height: 52rem;
        transition: all .8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

        &--front {
            background-color: $color-white;
        }

        &--back {
            transform: rotateY(180deg);

            &-1 {
                background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
            }

            &-2 {
                background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
            }

            &-3 {
                background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
            }
        }
    }
  

    &:hover &__side--front {
        transform: rotateY(-180deg);
    }

    &:hover &__side--back {
        transform: rotateY(0);
    }


    // FRONT SIDE STYLING
    &__picture {
        background-size: cover;
        height: 23rem;
        background-blend-mode: screen;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        
        &--1 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-1.jpeg);
        }

        &--2 {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), 
            url(../../img/card-2.jpeg);
        }

        &--3 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-3.jpeg);
        }
        &--4 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-4.jpeg);
        }

        &--5 {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), 
            url(../../img/card-5.jpeg);
        }

        &--6 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-6.jpeg);
        }
        &--7 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-7.jpeg);
        }

        &--8 {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), 
            url(../../img/card-8.jpeg);
        }

        &--9 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-9.jpeg);
        }
        &--10 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-10.jpeg);
        }
        &--11 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-11.jpeg);
        }
        &--12 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-12.jpeg);
        }
        &--13 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-13.jpeg);
        }
        &--14 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-14.jpeg);
        }
        &--15 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-15.jpeg);
        }
        &--16 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-16.jpeg);
        }
        &--17 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-17.jpeg);
        }
        &--18 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-18.jpeg);
        }
        &--19 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-19.jpeg);
        }
        &--20 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-20.jpeg);
        }
        &--21 {
            background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), 
            url(../../img/card-21.jpeg);
        }
    }

    &__heading {
        font-size: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: right;
        color: $color-white;
        position: absolute;
        top: 12rem;
        right: 2rem;
        width: 75%;
    
    }

    &__heading-span {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &--1 {
            background-image: linear-gradient(to right bottom, 
            rgba($color-secondary-light, .85), 
            rgba($color-secondary-dark, .85));
        }

        &--2 {
            background-image: linear-gradient(to right bottom, 
            rgba($color-primary-light, .85), 
            rgba($color-primary-dark, .85));
        }

        &--3 {
            background-image: linear-gradient(to right bottom, 
            rgba($color-secondary-light, .85), 
            rgba($color-secondary-dark, .85));
        }

    }

    &__details {
        padding: 3rem;

        ul {
            list-style: none;
            width: 80%;
            margin: 0 auto;

            li {
                text-align: center;
                font-size: 1.5rem;
                padding: 1rem;

                &:not(:last-child) {
                    border-bottom: 1px solid $color-grey-light-2;
                }
            }
        }
    }

    // FRONT SIDE STYLING
    &__cta {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        text-align: center;
    }

    &__price-box {
        text-align: center;
        color: $color-white;
        margin-bottom: 8rem;
    }

    &__price-only {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    &__price-value {
        font-size: 6rem;
        font-weight: 100;
    }





    //@include respond(tab-port) {
    @media only screen and (max-width: 56.25em),
            only screen and (hover: none) {

        height: auto;
        border-radius: 3px;
        background-color: $color-white;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
        
        &__side {
            height: auto;
            position: relative;
            box-shadow: none;

            &--back {
                transform: rotateY(0);
                clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
            }
        }
    
        &:hover &__side--front {
            transform: rotateY(0);
        }

    
        &__details {
            padding: 1rem 3rem;
        }

        // FRONT SIDE STYLING
        &__cta {
            position: relative;
            top: 0%;
            left: 0;
            transform: translate(0);
            width: 100%;
            padding: 7rem 4rem 4rem 4rem;
        }

        &__price-box {
            margin-bottom: 3rem;
        }

        &__price-value {
            font-size: 4rem;
        }
    }

}